import * as Routes from "./Routes";
import {Child} from "../models/Child";
import {DataService} from "./data.service";
import {GenericResponse} from "../models/GenericResponse.model";
import {HttpClient} from "@angular/common/http";
import { Injectable } from '@angular/core';
import {Observable} from "rxjs";
import {Response} from "../models/Response";
import {Router} from "@angular/router";

@Injectable({
    providedIn: 'root',
})
export class ChildService {


    constructor(
        private dataService: DataService,
        private http: HttpClient,
        private router: Router
    ) {
    }

    public create(child: Child): Observable<Response> {
        return this.http.post<Response>(`${Routes.CHILD_ROUTE}/`, child, this.dataService.getHttpOptions());
    }

    public update(child: Child): Observable<Response> {
        return this.http.put<Response>(`${Routes.CHILD_ROUTE}/${child.childId}/`, child, this.dataService.getHttpOptions());
    }

    public delete(child: Child): Observable<Response> {
        return this.http.delete<Response>(`${Routes.CHILD_ROUTE}/${child.childId}/`, this.dataService.getHttpOptions());
    }

    public getAll(userId: string): Observable<Response> {
        return this.http.get<Response>(`${Routes.CHILD_ROUTE}/byUser/${userId}`, this.dataService.getHttpOptions());
    }

    public getByChildId(childId: string): Observable<GenericResponse<Child>> {
        return this.http.get<GenericResponse<Child>>(`${Routes.CHILD_ROUTE}/${childId}/`, this.dataService.getHttpOptions());
    }
}
