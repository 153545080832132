import * as Routes from "./Routes";
import {DataService} from "./data.service";
import {GenericResponse} from "../models/GenericResponse.model";
import {HttpClient} from "@angular/common/http";
import { Injectable } from '@angular/core';
import {Observable} from "rxjs";
import {Project} from "../models/Project.model";
import {ProjectItem} from "../models/ProjectItem.model";
import {Router} from "@angular/router";

@Injectable({
    providedIn: 'root',
})
export class ProjectService {


    constructor(
        private dataService: DataService,
        private http: HttpClient,
        private router: Router
    ) {
    }

    public create(project: Project): Observable<GenericResponse<Project>> {
        return this.http.post<GenericResponse<Project>>(`${Routes.PROJECT_ROUTE}/`, project, this.dataService.getHttpOptions());
    }

    public update(project: Project): Observable<GenericResponse<Project>> {
        return this.http.put<GenericResponse<Project>>(`${Routes.PROJECT_ROUTE}/${project.projectId}/`, project, this.dataService.getHttpOptions());
    }

    public delete(projectId: string): Observable<GenericResponse<string>> {
        return this.http.delete<GenericResponse<string>>(`${Routes.PROJECT_ROUTE}/${projectId}/`, this.dataService.getHttpOptions());
    }

    public get(projectId: string): Observable<GenericResponse<Project>> {
        return this.http.get<GenericResponse<Project>>(`${Routes.PROJECT_ROUTE}/${projectId}/`, this.dataService.getHttpOptions());
    }

    public getAll(): Observable<GenericResponse<Project[]>> {
        return this.http.get<GenericResponse<Project[]>>(`${Routes.PROJECT_ROUTE}/all/byUser/`, this.dataService.getHttpOptions());
    }

    public getAllProjectItems(projectId: string): Observable<GenericResponse<ProjectItem[]>> {
        return this.http.get<GenericResponse<ProjectItem[]>>(`${Routes.PROJECT_ROUTE}/${projectId}/items/`, this.dataService.getHttpOptions());
    }

    public createProjectItem(item: ProjectItem): Observable<GenericResponse<ProjectItem>> {
        return this.http.post<GenericResponse<ProjectItem>>(`${Routes.PROJECT_ROUTE}/${item.projectId}/items/`, item, this.dataService.getHttpOptions());
    }

    public updateProjectItem(item: ProjectItem): Observable<GenericResponse<ProjectItem>> {
        return this.http.put<GenericResponse<ProjectItem>>(
            `${Routes.PROJECT_ROUTE}/${item.projectId}/items/${item.projectItemId}/`, item, this.dataService.getHttpOptions());
    }

    public deleteProjectItem(projectId: string, projectItemId: string): Observable<GenericResponse<string>> {
        return this.http.delete<GenericResponse<string>>(
            `${Routes.PROJECT_ROUTE}/${projectId}/items/${projectItemId}/`, this.dataService.getHttpOptions());
    }
}
